<template>
    <section class="card-house flex flex-warp">
        <div :class="`house-item room_status_${item.roomStatus}`" v-for="item in data" :key="item.apartmentUuid"
             @click="handleClickHouse(item)"
        >
            <div class="header flex align-center">
                <div class="house-name">{{ item.apartmentNo }}&nbsp;</div>
                <!--<div class="lessee-number">（{{ item.leasorNum || 0 }}人）</div>-->
            </div>
            <div class="main">
                <div class="lessee-item flex align-center" v-for="lessee in shopsOptions" :key="`${item.uuid}_${lessee.name}`">
                    <div class="label">{{ lessee.label }}：</div>
                    <div class="value">
                        <span>{{ item[lessee.name] }}</span>
                        <span v-if="lessee.name === 'leasorName'"> {{ item.leasorPhone }}</span>
                        <span v-if="item[lessee.name]"> {{ lessee.unit }}</span>
                    </div>
                </div>
            </div>
            <!--<div class="footer flex align-center justify-end" style="position:relative;top: -3px;">
                <img class="footer-icon" :title="createIcon('water', item.water).title"
                     :src="createIcon('water', item.water).src" alt="">
                <img class="footer-icon" :title="createIcon('elect', item.elect).title"
                     :src="createIcon('elect',item.elect).src" alt="">
                <img class="footer-icon" :title="createIcon('intelLock',item.intelLock).title"
                     :src="createIcon('intelLock',item.intelLock).src" alt="">
            </div>-->
            
            <div class="room-status">
                <div style="display: inline-block;margin-right: 5px" v-if="item.publishStatus===null ||item.publishStatus === 2" @click.stop="releaseShops(item)">
                    <el-tag size="medium" effect="dark">
                        待发布
                    </el-tag>
                </div>
                <el-badge :value="item.vacancyDay" :max="99" type="warning">
                    <el-tag :type="createRoomStatus(item.status)" size="medium" effect="dark">
                        {{ item.houseStatus }}
                    </el-tag>
                </el-badge>
            </div>
        </div>
    </section>
</template>

<script>
    import {shopsOptions, shopsDevice} from "../data";

    export default {
        props: {
            data: {
                type: Array,
                default: () => []
            },
            shopsStatus: {
                type: Array,
                default: () => []
            },
        },
        name: "card-shops",
        data() {
            return {
                shopsOptions
            };
        },
        methods: {
            handleClickHouse(item) {
                this.$emit('on-click', item)
            },
            createIcon(name, boolean) {
                // 水、电、智能锁状态图标
                const iconName = boolean ? "" : "_bed";
                const statusName = boolean ? '正常' : '损坏';
                const {label} = shopsDevice.find(v => v.name === name)
                const title = label + statusName
                const src = require(`@/assets/images/house/${name}${iconName}.png`);
                return {title, src}
            },
            createRoomStatus(status) {
                //1 发布 2签订 3空置
                // 房间状态
                /*if (!this.shopsStatus.map(v => v.code).includes(status)) return "--";
                const {codeName, dataValue} = this.shopsStatus.find(v => v.code === status);
                return {type: dataValue, codeName};*/
                return status === 1 ? 'success' : status === 2 ? 'warning' : '';
            },
            releaseShops(item){
                this.$emit('release-shops', item)
            }
        }
    };
</script>

<style lang="scss" scoped>
    .card-house {
        .house-item {
            min-width: 140px;
            min-height: 135px;
            width: VW(270px);
            height: VH(180px);
            color: #fff;
            border-radius: 4px;
            background: #68ACFD;
            padding: VH(10px) VW(20px);
            margin: 0 0 VH(20px) VW(20px);
            position: relative;
            cursor: pointer;

            .header {
                padding-bottom: VH(10px);

                .house-name {
                    font-size: rem(24px);
                }
            }

            .main {
                opacity: 0.63;
                font-size: rem(13px);

                .lessee-item {
                    padding-bottom: VH(5px);
                }
            }

            .footer-icon {
                width: VW(14px);
                padding-right: VW(5px);
            }

            .room-status {
                font-size: rem(14px);
                position: absolute;
                top: VH(10px);
                right: VW(20px);

            }
        }
    }
</style>
